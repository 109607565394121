export default {
  in: '入住',
  out: '离店',
  login: '登 录',
  lastName: '姓氏',
  firstName: '名字',
  invitationCode: '确认号',
  checkin: '入住日期',
  checkout: '离店日期',
  nights: '间夜',
  guests: '客人',
  search: '查询',
  accommodation: '酒店列表',
  bookings: '我的订单',
  china: '电话',
  overseas: '邮箱',
  wechat: '微信',
  signOut: '退出登录',
  hotelList: '酒店列表',
  showMore: '显示更多',
  hotelDetail: '酒店详情',
  description: '酒店描述',
  roomType: '房间类型',
  facilities: '酒店设施',
  reserve: '预约',
  cancellationPolicy: '取消政策',
  createReservation: '预订',
  yourInformation: '您的信息',
  mobileNumber: '手机号',
  yourMobileNumber: '您的手机号',
  email: '电子邮箱',
  yourEmail: '您的电子邮箱',
  yourFirstName: '您的名字',
  yourLastName: '您的姓氏',
  gender: '称谓',
  idType: '证件类型',
  idNumber: '证件号码',
  invitationCode1: '邀请码',
  yourIdNumber: '您的证件信息',
  yourInvitationCode: '您的邀请码',
  remark: '备注',
  reservationRemark: '我们会将您的需求发送给酒店，具体安排将与酒店保持一致',
  total: '总计',
  detail: '详情',
  bookNow: '预订',
  payment: '支付',
  totalAmount: '总金额',
  bookingDetails: '订单详情',
  payNow: '立即支付',
  back: '返回',
  offlinePayment: '对公转账',
  bookingInformation: '订单详情',
  offlinePayment2: '转账信息',
  companyName: '公司名称',
  companyBank: '开户银行',
  companyAccount: '银行账户',
  submit: '提交',
  'file size limit is {limit} KB': '图片上传不能大于 {limit} KB',
  bookingSuccess: '预定成功',
  successMessage: '提交成功！我们将以邮件形式发送通知。',
  emailMessage: '如未正确填写将无法收到预订确认信',
  bookingList: '订单列表',
  bookingNumber: '订单号',
  signOutConfirm: '注销登录吗？',
  sorry: '对不起',
  restaurant: '餐厅',
  laundry: '洗衣房',
  'lobby-bar': '大堂吧',
  gym: '健身房',
  'room-wifi': '房间wifi',
  'swimming-pool': '游泳池',
  'non-smoking-floor': '无烟楼层',
  'meeting-room': '会议室',
  'butler-service': '管家服务',
  'parking-lot': '停车场',
  'for-disabled': '残疾人设施',
  'pickup-dropoff': '接送服务',
  Male: '男士',
  Female: '女士',
  readAndAccept: '阅读并同意',
  cancellationPolicies: '取消政策',
  'reservationStatus.': '您还没有预订',
  reservationStatus: {
    Unpaid: '待支付',
    Cancelled: '取消',
    Reserved: '待入住',
    'Checked In': '已入住',
    'Checked Out': '已退房',
    'No Show': '预订未到'
  },
  'confirm to cancel this booking': '您确定要取消预订吗',
  'no booking': '您还没有预订',
  invoice: '发票',
  'all rooms sold out': '所有房型已售完',
  createInvoice: '添加发票',
  invoiceTitle: '发票抬头',
  invoiceType: '发票类型',
  pleaseSelectInvoiceType: '请选择发票类型',
  'hotel expense': '酒店发票',
  'hotel expense(hotel)': '酒店住宿费发票',
  service: '平台发票',
  'invoice provided by platform': '平台住宿发票',
  invoiceName: '单位名称',
  invoiceTaxId: '税号',
  invoiceAddress: '单位地址',
  invoiceTelephone: '电话号码',
  invoiceBank: '开户银行',
  invoiceAccount: '银行账户',
  selectInvoiceType: '选择发票类型',
  pleaseInputInvoiceTitle: '请输入发票单位名称',
  pleaseInputTaxId: '请输入发票税号',
  pleaseInputAddress: '请输入单位地址',
  pleaseInputTelephone: '请输入电话号码',
  pleaseInputBank: '请输入开户银行',
  pleaseInputBankAccount: '请输入银行账户',
  mailInformation: '邮寄信息',
  mailName: '收件人姓名',
  mailNumber: '手机号',
  mailAddress: '收件地址',
  pleaseInputMailName: '请填写收件人姓名',
  pleaseInputMailNumber: '请填写收件人手机号',
  pleaseInputMailAddress: '请填写快递地址',
  mailNote: '发票将在退房后5-7个工作日内寄出。',
  invoiceNote: '备注: 住宿类发票将在您离店的时候由酒店开具给您，请您在离店时及时向酒店索要。',
  'VAT invoice Normal': '增值税普通发票',
  'VAT invoice Special': '增值税专用发票',
  invoiceNameRequired: '请填写发票名称',
  invoiceTaxIdRequired: '请填写税号',
  invoiceAddressRequired: '请填写单位地址',
  invoiceTelephoneRequired: '请填写电话号码',
  invoiceBankRequired: '请填写开户银行',
  invoiceAccountRequired: '请填写银行账户',
  invoiceCategory: '发票种类',
  selectInvoiceCategory: '选择发票种类',
  'upload file only for JPG/JPEG/PNG , and it is limited to 5MB': '只能上传jpg/png文件，且不超过5MB',
  'file size limit is {limit} MB': '图片大小超过 {limit} MB',
  accompany: '同住人',
  accompanyNames: '同住人姓名',
  paymentRemark: '便于加快您的订单付款审核,请填写您的付款账号与付款名称',
  hotelConfirmNumber: '酒店确认号',
  arrivalTime: '抵店时间',
  departureTime: '退房时间',
  'arrival time is required': '请选择抵店时间',
  'departure time is required': '请选择退房时间',
  'login notify': '很抱歉通知您，您提供的名字或者邀请码不正确，请重新输入或者与酒店预订客服联系。',
  'customer tel': '客服电话',
  'customer email': '客服邮箱',
  day: '天',
  hour: '小时',
  minute: '分钟',
  second: '秒',
  'for more information': '官方住宿预订详情请咨询',
  'footer customer tel': '客服电话',
  reservationNightsMessage: '预订需要至少连续入住两晚。',
  'do you need invoice': '是否需要发票?',
  tips: '提示',
  'payment method not selected': '请选择付款方式',
  'payment notice': '友情提示：支付方式一旦选择，将无法更改。'
}
